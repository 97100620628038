<template>
    <div class="sideStreamList">
        <div :key="stream.station_id" class="channel" v-for="stream in whitelistedStreams" :class="{ currentChannel: stream.station_id == activeStream }">
            <div class="streamInfo">
                <div v-if="showtext == true" class="stream">{{stream.stream}}</div>
                <p @click="play(stream)" :data-newstationid="stream.station_id" :data-channel="stream.station_id" :title="songMeta(stream)" class="startStream"></p>
                <img :alt="stream.stream"
                     :src="stream.stream_logo"
                     class="streamBG">
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import $ from 'jquery';

    export default {
        name: "grid",
        props: {
            streamConfig: Object,
            streamService: Object,
        },
        data: function() {
          return {
              href: document.location.href,
              activeStream: 0,
              activeStation: 0,
              showtext: true
          }
        },
        methods: {
            songMeta(stream) {
                if(stream) {
                    return stream.artist_name + ' mit ' + stream.song_title;
                }
            },
            play(stream){
                let referrer = this.streamConfig.url;

                window.parent.location.href = referrer + '?stream=' + stream.station_id;

            },
        },
        computed: {
            whitelistedStreams: function () {
                let streams = [];
                if (this.streamService) {
                    let that = this;
                    Object.keys(this.streamService).forEach(function (key) {
                        if(that.streamConfig.streams){
                            for(let [streamId] of Object.entries(that.streamConfig.streams)){
                                if(streamId == that.streamService[key].station_id){
                                    streams.push(that.streamService[key]);
                                }
                            }
                        }
                    })
                }

                return streams;
            },
        },
        beforeMount() {
            let showtext = document.getElementById('app').getAttribute('data-showtext');
            if(showtext === 'false'){
                this.showtext = false
            }

            let that = this;

            window.onload = function(){
                const href = document.location.href;
                if(href.search('stream=') >= 0){
                    const split = document.location.href.split('?stream=');
                    that.activeStream = split[1];
                    if(split[1].search('#') >= 0){
                        split[1] = split[1].replace('#', '')
                    }
                    that.activeStation = that.streamConfig.streams[split[1]];
                }
            }
        },
        watch: {
            activeStream: function(){
                if(this.activeStream){
                    let that = this;
                    Object.keys(this.streamService).forEach(function (key) {
                        if (that.streamService[key].station_id == that.activeStream) {
                            const streamUrl = that.streamService[key]['url_high'].replace('homepage', 'radioplayer');
                            window.radioplayer.emp.loadHTTPStream(streamUrl);
                        }
                    })
                }
            },
            activeStation: function(){
                const that = this;
                try {
                    const url = `https://np.radioplayer.de/qp/v3/onair?rpIds=${that.activeStation}&nameSize=200&artistNameSize=200&descriptionSize=200&callback=JSON.stringify&_=1582534068564`;
                    axios.get(url).then(function(response){
                        let data = eval(response.data);
                        let json = JSON.parse(data);
                        window.currentStationID = that.activeStation;
                        let stationInfo = json.results[that.activeStation][0];
                        $(window.radioplayer.playing).trigger('receiveData', [[stationInfo]])
                        window.radioplayer.playing.updateText(stationInfo.description, stationInfo.type)
                    })
                } catch (e) {
                    throw new Error(e.message)
                }

            }
        }
    }
</script>

<style scoped>
    html{
        height: 100%;
    }

    .channel {
        position: relative;
        z-index: 1;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
        display: -webkit-box;
        display: flex;
        background: rgba(0,0,0,.25);
        color: #fff;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
        -webkit-box-align: center;
        align-items: center;
        scroll-snap-align: start;
        padding: 24px;
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    .channel{
        width: 178px;
        height: 178px;
        border-radius: 10px;
    }

    .channel:hover{
        cursor:pointer;
    }

    .channel.currentChannel .startStream{
        background: rgba(0,0,0,0.7);
        margin: 10px;
        border-radius: 10px;
        z-index:0;
    }

    .channel.currentChannel .stream{
        z-index:1;
    }

    .channel .streamInfo {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        max-width: 100%;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .sideStreamList {
        grid-template-columns: repeat(auto-fit,minmax(auto,178px));
        position: relative;
        display: grid;
        grid-gap: 5px;
        grid-auto-columns: 33.33vw;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 0 24px 12px;
        -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity;
        scroll-padding: 0 0 0 24px;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }

    .channel .stream {
        font-size: 22px;
        line-height: 22px;
        max-width: 100%;
        font-weight: 800;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    .channel .startStream {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
    }

    .channel .hoverBox {
        border-radius: 10px;
    }

    .uk-icon {
        margin: 0;
        border: none;
        border-radius: 0;
        overflow: visible;
        font: inherit;
        line-height: inherit;
        color: inherit;
        text-transform: none;
        padding: 0;
        background-color: transparent;
        display: inline-block;
        fill: currentcolor;
        line-height: 0;
    }

    .channel .streamBG {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        max-width: 100%;
    }

    @media (min-width: 640px){
        .sideStreamList {
            grid-gap: 10px;
            grid-auto-columns: 20vw;
        }
    }

    @media (min-width: 960px){
        .sideStreamList {
            grid-template-rows: auto;
            grid-auto-rows: auto;
            grid-auto-columns: auto;
            grid-auto-flow: dense;
            overflow: visible;
            margin: 0;
            padding: 0;
        }

        .sideStreamList .channel::after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 0;
            padding-bottom: 100%;
        }

        .channel .hoverBox .playButton svg {
            width: 40px;
            height: 40px;
        }

        .channel .stream {
            font-size: 1rem;
        }

        .channel .startStream {
            padding: 15px;
            margin: 0;
        }

        .channel .startStream {
            z-index: 2;
        }

        .channel .hoverBox {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,61,118,.7);
            padding: 12px;
            z-index: 1;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-pack: justify;
            justify-content: space-between;
            opacity: 0;
            will-change: opacity;
            -webkit-transition: opacity .25s ease-out;
            transition: opacity .25s ease-out;
        }

        .channel .hoverBox .artist {
            -webkit-transition-delay: .1s;
            transition-delay: .1s;
        }

        .channel .hoverBox .artist, .channel .hoverBox .playButton, .channel .hoverBox .song {
            opacity: 0;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
            will-change: opacity;
            -webkit-transition: opacity .25s ease-out,-webkit-transform .25s ease-out;
            transition: opacity .25s ease-out,-webkit-transform .25s ease-out;
            transition: opacity .25s ease-out,transform .25s ease-out;
            transition: opacity .25s ease-out,transform .25s ease-out,-webkit-transform .25s ease-out;
            transition-delay: 0s, 0s, 0s;
        }

        .channel .hoverBox .artist {
            font-weight: 800;
        }

        .channel .hoverBox .artist, .channel .hoverBox .song {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: .85rem;
            line-height: 1.2;
        }

        .channel .hoverBox .playButton {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            margin: auto;
            display: -webkit-box;
            display: flex;
        }

        .channel .hoverBox .playButton {
            -webkit-transition-delay: .2s;
            transition-delay: .2s;
        }

        .channel .hoverBox .song {
            -webkit-transition-delay: .3s;
            transition-delay: .3s;
        }

    }

    @media (min-width: 1400px){
        .sideStreamList {
        }

        .channel .stream {
            font-size: 1.1rem;
        }

        .channel .hoverBox {
            padding: 20px;
        }

        .channel .hoverBox .playButton svg {
            width: 46px;
            height: 46px;
        }
    }

    @media (min-width: 1600px){
        .sideStreamList {
        }
    }
</style>
