<template>
  <div id="app">
    <StreamList/>
  </div>
</template>

<script>
import StreamList from './components/StreamList.vue'

export default {
  name: 'app',
  components: {
    StreamList
  },

}
</script>
