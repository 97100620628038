<template>
    <div v-if="streamConfig && streamServiceData" class="streamList">
        <grid :stream-service="streamServiceData" :stream-config="streamConfig"></grid>
    </div>
    <div class="error" v-else>
        Kein Streamservice angegeben oder keine Config angelegt
    </div>
</template>

<script>
    import axios from 'axios';
    import Grid from './grid/grid';

    export default {
        name: "streamlist",
        components: {
            Grid
        },
        data: function() {
            return {
                streamConfig: {},
                streamServiceData: {},
                streamServiceUrl: "",
                streamName: "",
            }
        },
        methods: {
            getStreamService () {
                axios.get(this.streamServiceUrl).then(response => {
                    this.streamServiceData = response.data;
                })
            },
            loadStreamConfig () {
                axios.get('https://radioplayerscript.raudio-online.de/config/config.json').then(response => {
                    this.streamConfig = response.data[this.streamName];
                })
            },
        },
        beforeMount: function() {
            this.streamServiceUrl = document.getElementById('app').getAttribute('data-streamservice')
            this.streamName = this.streamServiceUrl.split('/').pop()
            if (this.streamName) this.loadStreamConfig()
            if (this.streamServiceUrl) this.getStreamService()
        }
    }
</script>

<style scoped>

</style>
